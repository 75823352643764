import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { formatSeriesSlug } from "../helpers"

function reducer(
  state: { [x: string]: any[] },
  model: { series: { name: any } }
) {
  const seriesName = formatSeriesSlug(model.series.name)
  if (!state[seriesName]) {
    state[seriesName] = []
  }
  state[seriesName].push(model)
  return state
}

const useModelData = () => {
  const data = useStaticQuery(graphql`
    query {
      models: allSanityModel {
        nodes {
          _id
          grade
          name
          year
          code
          series {
            currentYear
            name
          }
        }
      }
      series: allSanitySeries {
        nodes {
          name
          currentYear
        }
      }
    }
  `)
  const modelsData = {
    models: data.models.nodes.reduce(reducer, {}),
    series: data.series.nodes,
  }
  return { modelsData }
}
export default useModelData
