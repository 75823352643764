import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import { ModelComparisonContext } from "../../../../contexts/ModelComparison/context"
import useModelCompareData from "../../../../hooks/useModelCompareData"
import { Image } from "../../../atoms/Image"
import { ButtonLink } from "../../../atoms/Button"
import {
  generateInventoryLink,
  normalizeSeriesNameForLink,
  vehicleCategory,
} from "../../../../helpers"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { ModelCompareFooterProps } from "./ModelCompareFooter.d"

const ModelCompareFooter: React.FC<ModelCompareFooterProps> = ({
  modelsDetails,
  modelIds,
}) => {
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(ModelComparisonContext)
  const { trackTealEvent } = useTealiumEvent()

  return (
    <>
      {modelsDetails.length > 0 && (
        <div
          css={[
            tw`px-4 pb-8 max-w-desktop mx-auto min-h-[6rem] mb-10`,
            `background: linear-gradient(180deg, #FFFFFF 40%, #f8f9fa 0%);
        background-repeat: no-repeat;
        background-size: calc(100%) calc(100%);
        background-position: bottom;`,
          ]}
        >
          <div css={[tw`grid grid-cols-1 md:grid-cols-3 px-3`]}>
            <div css={[tw`col-span-1 flex flex-col gap-1 pointer-events-none`]}>
              <Image
                css={[tw`max-w-[20rem]`]}
                imageData={modelsDetails[0]?.colors[0]?.exteriorImages[1]}
              />
              <p css={[tw`font-semibold pl-5`]}>{state.year}</p>
              <p css={[tw`font-semibold uppercase text-xl pl-5`]}>
                {state.model}
              </p>
            </div>
            <div
              css={[
                tw`uppercase col-span-1 my-5 flex flex-col  justify-center gap-5 md:(my-0 flex-row items-end col-span-2) lg:(col-span-1)`,
              ]}
            >
              <ButtonLink
                secondary
                to={generateInventoryLink({
                  series: state.model.toLowerCase(),
                  modelNumber: modelIds,
                })}
                css={[tw`whitespace-nowrap`]}
                onClick={() => {
                  trackTealEvent({
                    tealium_event: "view_inventory_click",
                    vehicle_model_truncated: state.model,
                    coupon_module_text: `View ${state.model} Inventory`,
                    vehicle_year: state.year,
                    vehicle_model: state.model,
                    vehicle_segment_vehicle_page: vehicleCategory(
                      state.model.toLowerCase()
                    ),
                    link_href: generateInventoryLink({
                      series: state.model.toLowerCase(),
                    }),
                  })
                }}
                analytics-id={`inventory:series bar:`}
              >
                {_(`View Inventory`)}
              </ButtonLink>
              <ButtonLink
                to={`/offers/${normalizeSeriesNameForLink(state.model)}`}
                secondary
                onClick={() => {
                  trackTealEvent({
                    tealium_event: "cta_click",
                    vehicle_model_truncated: state.model,
                    coupon_module_text: `View Offers`,
                    vehicle_year: state.year,
                    vehicle_model: state.model,
                    vehicle_segment_vehicle_page: vehicleCategory(
                      state.model.toLowerCase()
                    ),
                    link_href: `/offers/${normalizeSeriesNameForLink(
                      state.model
                    )}`,
                  })
                }}
                analytics-id={`series offers:series bar:`}
              >
                {_(`View Offers`)}
              </ButtonLink>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModelCompareFooter
