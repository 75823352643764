import tw from "twin.macro"
import { Button } from "../../atoms/Button"
import { css } from "@emotion/react"
import { PlaceholderModelCompareCardProps } from "./ModelCompareCard.d"
import { useContext } from "react"
import { ModelComparisonContext } from "../../../contexts/ModelComparison/context"
import noVehicleSelected from "../../../images/favorite-vehicle-empty-state.png"
import { toggleVehicleSelectModal } from "../../../contexts/ModelComparison/actions"
import { LanguageContext } from "../../../contexts/Language"

const PlaceholderModelCompareCard: React.FC<
  PlaceholderModelCompareCardProps
> = ({ isSticky }) => {
  const [state, dispatch] = useContext(ModelComparisonContext)
  const { _ } = useContext(LanguageContext)

  return (
    <div>
      <div
        css={[
          tw`col-span-1 h-full flex flex-col place-content-center text-center gap-10 px-4 py-4 min-w-[16rem] md:min-w-0`,
          `transition: all 0.3s ease-in-out;`,
          isSticky ? tw`opacity-0 h-0` : tw`opacity-100 h-[22rem]`,
        ]}
      >
        <img
          src={noVehicleSelected}
          css={[
            tw`object-cover `,
            `transition: all 0.3s ease-in-out;`,
            isSticky ? tw`opacity-0 h-0` : tw`opacity-100 h-32`,
          ]}
          alt=""
        />
        <p
          css={[
            tw`font-semibold text-lg`,
            css`
              text-wrap: balance;
            `,
          ]}
        >
          {/* {_("Select a Series & Model")} */}
        </p>
        <Button
          primary
          onClick={() => {
            dispatch(toggleVehicleSelectModal())
          }}
        >
          {_("Add a Vehicle")}
        </Button>
      </div>
    </div>
  )
}
export default PlaceholderModelCompareCard
