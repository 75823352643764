import React from "react"
import tw from "twin.macro"
import Icon from "../../../atoms/Icon"
import { LanguageContext } from "../../../../contexts/Language"
import { useContext } from "react"
import { Accordion } from "../../../atoms/Accordion"
import { ContactContext } from "../../../../contexts/Contact"
import { ModelComparisonContext } from "../../../../contexts/ModelComparison/context"
import type { ModelCompareChartProps } from "./ModelCompareChart.d"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../../helpers"

const ModelCompareChart: React.FC<ModelCompareChartProps> = ({
  expandAll,
  initialLoad,
  modelsDetails,
  sortedCategories,
}) => {
  const { _, language } = useContext(LanguageContext)
  const [_contactState, contactDispatch] = useContext(ContactContext)
  const [state, dispatch] = useContext(ModelComparisonContext)
  const [disclaimerState, disclaimerDispatch] = useContext(DisclaimersContext)

  return (
    <>
      {sortedCategories && (
        <div css={[tw`mb-10`]}>
          <div>
            <div css={[tw`p-4 max-w-desktop mx-auto`]}>
              {sortedCategories?.map(
                (category: { name: string; items: any[] }, index: number) => (
                  <div key={`${category}-${index}`}>
                    <Accordion
                      expanded={
                        expandAll
                          ? true
                          : index === 0 && initialLoad
                          ? true
                          : false
                      }
                      label={category?.name}
                      css={[
                        tw`border-t items-start border-gray-500 px-5 pl-0 py-6 mt-0 font-light	 text-2xl`,
                        tw`md:(px-5 py-6 mt-0)`,
                      ]}
                      analyticsId={`compare:${index + 1}`}
                    >
                      <div css={[tw``]}>
                        <ul>
                          {category?.items?.map(
                            (item: string, index: number) => {
                              const items = modelsDetails.map(
                                (trim: {
                                  series: { name: string }
                                  grade: string
                                  name: string
                                  comparisons: { en: any[]; es: any[] }
                                }) => {
                                  const features =
                                    language == "en"
                                      ? trim?.comparisons?.en?.find(
                                          item => item.name === category.name
                                        )
                                      : trim?.comparisons?.es?.find(
                                          item => item.name === category.name
                                        )
                                  return {
                                    items: features ? features.items : [],
                                    series: `${trim.series.name} ${trim.grade}`,
                                    trim: trim?.name.replace(state.model, ""),
                                  }
                                }
                              )
                              return (
                                <li
                                  css={[
                                    tw`grid grid-cols-1 gap-y-4 md:grid-cols-4 md:gap-y-12`,
                                    index % 2 == 0 && tw`bg-gray-50`,
                                  ]}
                                >
                                  <div css={[tw`col-span-1 p-6 font-semibold`]}>
                                    {parseDisclaimerBlocks(item, code =>
                                      disclaimerDispatch(
                                        toggleDisclaimersModal(code)
                                      )
                                    )}
                                  </div>
                                  {items?.map((trim: any, i: number) => {
                                    const feature = trim?.items?.find(
                                      (trimItem: { title: string }) =>
                                        trimItem.title === item
                                    )
                                    return (
                                      <div
                                        css={[
                                          tw`col-span-1 flex justify-between md:justify-center items-center p-6`,
                                          i % 2 !== 0 &&
                                            tw` md:(bg-gray-200 bg-opacity-30)`,
                                        ]}
                                      >
                                        <div
                                          css={[
                                            tw`flex flex-col gap-2 max-w-[50%] md:hidden`,
                                          ]}
                                        >
                                          <p
                                            css={[tw`font-semibold uppercase`]}
                                          >
                                            {trim.series}
                                          </p>
                                          <p css={[tw`text-xs`]}>
                                            {trim.model}
                                          </p>
                                        </div>
                                        {feature?.hasOwnProperty(
                                          "description"
                                        ) && feature.description !== "S" ? (
                                          <p>{feature?.description}</p>
                                        ) : feature?.availability == "S" ||
                                          feature?.description == "S" ? (
                                          <Icon.Standard
                                            color="blue"
                                            css={[tw`h-5 w-5`]}
                                          />
                                        ) : feature?.availability == "A" ? (
                                          <Icon.Available
                                            color="blue"
                                            css={[tw`h-5 w-5`]}
                                          />
                                        ) : (
                                          <Icon.X
                                            color="black"
                                            css={[tw`h-4 w-4`]}
                                          />
                                        )}
                                      </div>
                                    )
                                  })}
                                </li>
                              )
                            }
                          )}
                        </ul>
                      </div>
                    </Accordion>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModelCompareChart
