import React from "react"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import { useContext } from "react"
import { LanguageContext } from "../../../contexts/Language"

const ModelCompareKey = () => {
  const { _ } = useContext(LanguageContext)

  return (
    <>
      <div css={[tw`flex items-center gap-3`]}>
        <Icon.Standard color="blue" css={[tw`h-5 w-5`]} /> {_("Standard")}
      </div>
      <div css={[tw`flex items-center gap-3`]}>
        <Icon.Available color="blue" css={[tw`h-5 w-5`]} /> {_("Available")}
      </div>
      <div css={[tw`flex items-center whitespace-nowrap gap-3`]}>
        <Icon.X color="black" css={[tw`h-4 w-4`]} /> {_("Not Available")}
      </div>
    </>
  )
}
export default ModelCompareKey
