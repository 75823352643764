import { PageProps, graphql } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import { SEO } from "../components/atoms/SEO"
import SelectVehicleCompareModal from "../components/organisms/ModelComparison/SelectVehicleCompareModal/SelectVehicleCompareModal"
import ModelCompareHero from "../components/organisms/ModelComparison/ModelCompareHero/ModelCompareHero"
import { ModelComparisonContext } from "../contexts/ModelComparison/context"
import ModelCompareChart from "../components/organisms/ModelComparison/ModelCompareChart/ModelCompareChart"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { useContext, useEffect, useState } from "react"
import ModelCompareFooter from "../components/organisms/ModelComparison/ModelCompareFooter/ModelCompareFooter"
import useModelCompareData from "../hooks/useModelCompareData"
import { vehicleCategory } from "../helpers"

const ModelComparisonPage: React.FC<PageProps> = ({
  location,
  pageContext,
  data,
}: any) => {
  const [expandAll, setExpandAll] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [state, dispatch] = useContext(ModelComparisonContext)

  const { modelsDetails, modelIds, sortedCategories, modelsData } =
    useModelCompareData(pageContext.slug, state.year, state.trims, data)
  // Tealium
  const { handleTealView } = useTealiumView()
  useEffect(() => {
    if (state?.model) {
      handleTealView({
        page_name: `${vehicleCategory(
          state?.model
        )}_${state?.model.toLowerCase()}`,
        tealium_event: "model_compare_series",
        page_type: "comparison",
        vehicle_model: state?.model,
        vehicle_segment_vehicle_page: vehicleCategory(state?.model),
        vehicle_model_truncated: state?.model,
      })
    } else {
      handleTealView({
        page_name: "comparison",
        tealium_event: "model_compare",
        page_type: "comparison",
      })
    }
  }, [])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={"Compare Toyota Model Trim Levels | Southeast Toyota Dealers"}
        keywords={""}
        schema={`{
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "Southeast Toyota Model Comparison",
          "description": "Find the right Toyota for you! Select a Toyota model to compare different Toyota trims for MPG, price, capabilities, packages, safety features, & so much more.",
          "image": "https://cdn.sanity.io/images/90qxor39/production/e2030a9712af4a5f732e8e6da4a4dc3e1e3cb3e6-1200x663.png",
          "logo": "https://cdn.sanity.io/images/90qxor39/production/9bf3dd539c9eac39c1a4b2465e19d7191702222e-500x415.jpg",
          "url": "https://exploresetoyota.com/compare",
          "sameAs": ["https://twitter.com/ExploreSEToyota","https://www.facebook.com/exploresetoyota/","https://www.youtube.com/c/LocalToyotaDealers","https://www.instagram.com/exploresetoyota/","https://en.wikipedia.org/wiki/Southeast_Toyota_Distributors","https://www.pinterest.com/ExploreSEToyota/"],
          "address": {
          "@type": "PostalAddress",
          "addressCountry": "United States"
          }
          }`}
        description={
          "Find the right Toyota for you! Select a Toyota model to compare different Toyota vehicles MPG, price, capabilities, packages, safety features & so much more."
        }
        canonicalPath={""}
      />
      <SelectVehicleCompareModal
        active={true}
        series={pageContext.slug}
        modelsData={modelsData}
      />
      <ModelCompareHero
        expandAll={expandAll}
        setExpandAll={setExpandAll}
        setInitialLoad={setInitialLoad}
        modelsDetails={modelsDetails}
        modelIds={modelIds}
      />
      <ModelCompareChart
        expandAll={expandAll}
        initialLoad={initialLoad}
        modelsDetails={modelsDetails}
        sortedCategories={sortedCategories}
      />
      <ModelCompareFooter modelsDetails={modelsDetails} modelIds={modelIds} />
    </Layout>
  )
}

export default ModelComparisonPage

export const query = graphql`
  query modelComparisonQuery($slug: String) {
    models: allSanityModel(filter: { series: { slug: { eq: $slug } } }) {
      nodes {
        _id
        grade
        name
        year
        code
        series {
          currentYear
          name
        }
        comparisons {
          en {
            items {
              _key
              _type
              title
              availability
            }
            name
            _key
          }
          es {
            _key
            items {
              title
              availability
              _key
              _type
            }
            name
            _key
          }
        }
        colors {
          exteriorImages {
            asset {
              altText
              _id
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
        standardEquipment {
          en {
            _key
            type
            items {
              name
              items {
                title
                description
              }
            }
          }
          es {
            _key
            type
            items {
              name
              items {
                title
                description
              }
            }
          }
        }
      }
    }
  }
`
