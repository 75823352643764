import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import SelectField from "../../../molecules/ContactDealer/SelectField"
import { LanguageContext } from "../../../../contexts/Language"
import {
  cleanUpModels,
  combineSeriesAndSeriesHybridModels,
  formatSeriesSlug,
  seriesNameMap,
  vehicleCategory,
} from "../../../../helpers"
import { SelectVehicleCompareModalProps } from "./SelectVehicelCompareModal.d"
import { Modal } from "../../../atoms/Modal"
import { ModelComparisonContext } from "../../../../contexts/ModelComparison/context"
import { Button, ButtonLink } from "../../../atoms/Button"
import {
  addTrim,
  toggleVehicleSelectModal,
  updateModel,
  updateTrim,
} from "../../../../contexts/ModelComparison/actions"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { Buffer } from "buffer"
import Icon from "../../../atoms/Icon"
import useModelData from "../../../../hooks/useModelData"

const SelectVehicleCompareModal: React.FC<SelectVehicleCompareModalProps> = ({
  active,
  series = null,
}) => {
  const [state, dispatch] = useContext(ModelComparisonContext)
  const [seriesName, setSeriesName] = useState(series || null)
  const [modelName, setModelName] = useState(state.selectedTrim || null)
  const [seriesYear, setSeriesYear] = useState(state.year || null)
  const { modalOpen } = state
  const { trackTealEvent } = useTealiumEvent()
  const { _ } = useContext(LanguageContext)
  const { modelsData } = useModelData()
  // Stores all models of selected series (based on seriesName)
  const [modelOptions, setModelOptions] = useState(
    modelsData.models[formatSeriesSlug(seriesName)]
  )
  const [showChangeWarning, setShowChangeWarning] = useState(false)

  useEffect(() => {
    setSeriesName(seriesNameMap.get(series)), setSeriesYear(state.year)
    state.selectedTrim && setModelName(state.selectedTrim)
    state.selectedModel && setShowChangeWarning(true)
    if (state.modalOpen) {
      document.body.style.overflow = "hidden"
      setModelName(null)
    } else {
      document.body.style.overflow = "auto"
    }
  }, [state.modalOpen])

  // When seriesName changes (user selects another series) models state changes to models of newly selected series
  // Set the series year based on the current year of the selected series
  useEffect(() => {
    const allSeriesModels = modelsData.models[formatSeriesSlug(seriesName)]
    // If the user is trying to update a trim, prefill the selected trim
    state.selectedTrim && setModelName(state.selectedTrim)
    // Find the most recent year of the selected series
    let mostRecentYear = null
    if (Array.isArray(allSeriesModels) && allSeriesModels?.length > 0) {
      mostRecentYear = Math.max(
        ...allSeriesModels?.map((option: { year: number }) => option.year)
      )
    } else {
      mostRecentYear = new Date().getFullYear()
    }

    const filteredOptions =
      modelsData &&
      seriesName &&
      seriesName &&
      cleanUpModels(
        modelsData.models[formatSeriesSlug(seriesName)],
        seriesName,
        mostRecentYear
      ).map((option: { name: string }) => {
        return {
          ...option,
          disabled: state.trims.includes(option.name),
        }
      })
    setModelOptions(filteredOptions)
    //update series year
    setSeriesYear(mostRecentYear.toString())
    // If the user is trying to update a trim, prefill the selected trim
    state.selectedTrim && setModelName(state.selectedTrim)
  }, [seriesName, modalOpen])

  const sortedSeriesData = combineSeriesAndSeriesHybridModels(
    modelsData.series
      ?.sort((a: { name: string }, b: { name: any }) =>
        a?.name?.localeCompare(b?.name)
      )
      .filter(
        (series: { name: string }) =>
          !["Avalon", "Avalon Hybrid", "C-HR"].includes(series.name)
      )
  )

  // Model validation - a model is required when a series is selected
  const [showModelVal, setShowModelVal] = useState(false)

  if (!active) return null

  const handleSave = () => {
    if (seriesName && modelName) {
      state.selectedTrim && state.selectedTrim !== modelName
        ? dispatch(updateTrim(modelName))
        : dispatch(addTrim(seriesName, seriesYear, modelName))
      trackTealEvent({
        tealium_event: "compare_add",
        vehicle_model: seriesName,
        vehicle_trim: modelName,
        vehicle_trim_id: modelOptions
          .find((model: { name: any }) => model.name === modelName)
          ._id?.split(".")[3],
        vehicle_segment_vehicle_page: vehicleCategory(seriesName.toLowerCase()),
        vehicle_model_truncated: seriesName,
      })
    } else {
      dispatch(toggleVehicleSelectModal())
    }
  }
  const generateLink = () => {
    // Encode the full state to base64
    const stateJsonString = JSON.stringify([...state.trims, modelName])
    const base64String = Buffer.from(stateJsonString).toString("base64")
    // Set the URL to include that value for the s query param
    const queryString = base64String ? `?s=${base64String}` : ""

    if (!seriesName) {
      return `/compare`
    }
    return `/${formatSeriesSlug(seriesName)}/compare${queryString}`
  }
  return (
    <Modal
      css={[
        tw`w-full h-full max-h-full bg-gray-50`,
        tw`lg:(max-w-[25rem] max-h-[475px])`,
        "z-index: 9999;",
      ]}
      open={modalOpen}
      onClose={() => {
        dispatch(toggleVehicleSelectModal())
      }}
    >
      {!showChangeWarning ? (
        <div
          css={[
            tw`flex flex-col h-full items-center justify-center px-5 mt-4 mx-auto max-w-[24rem]`,
          ]}
        >
          <h3 css={[tw`text-center text-2xl mb-8`]}>
            {_("Select Model & Trim")}
          </h3>

          {seriesName && !showChangeWarning && seriesName == state.model ? (
            <div css={[tw`text-center font-semibold normal-case mt-9 mb-6`]}>
              <p>
                {`Model:`} <span css={[tw`uppercase`]}>{seriesName}</span>
              </p>
              <button
                css={[tw`text-toyotaRed text-sm`]}
                onClick={() => {
                  setShowChangeWarning(true)
                }}
              >
                {_("Change Model")}
              </button>
            </div>
          ) : (
            <>
              <h3 css={[tw`text-center font-semibold normal-case mt-6 mb-3`]}>
                {_("Which model?")}
              </h3>
              <SelectField
                active={true}
                options={sortedSeriesData}
                onChange={([value]) => {
                  setSeriesName(value)
                  setModelName(null)
                }}
                fieldName="series"
                label={_("Select")}
                value={[seriesName, seriesName || _("Select")]} // Display Select when series is null
                css={[tw`max-w-xs`]}
                showVal={false}
              />
            </>
          )}

          <h3 css={[tw`text-center font-semibold normal-case mt-6 mb-3`]}>
            {_("Select a Trim")}
          </h3>
          <SelectField
            active={active}
            showVal={showModelVal}
            options={modelOptions}
            onChange={([value]) => {
              setShowModelVal(false)
              setModelName(value)
            }}
            fieldName="model"
            label={_("Select")}
            validationMessage={_("Please select a trim.")}
            value={[modelName, modelName?.toUpperCase() || _("Select")]} // Display Select when trim is null
            css={[tw`max-w-xs uppercase`]}
          />
          <div css={[tw`h-20 lg:h-28 w-full flex`]}>
            <ButtonLink
              css={[tw`place-self-end w-full`]}
              primary
              onClick={handleSave}
              disabled={!modelName}
              analytics-id="add vehicle:hero"
              to={generateLink()}
            >
              {_("Add to Compare")}
            </ButtonLink>
          </div>
        </div>
      ) : (
        <div
          css={[
            tw`h-full flex flex-col gap-5 items-center justify-center font-semibold normal-case p-5 mx-auto max-w-[24rem]`,
          ]}
        >
          <Icon.Warning color="red-400" css={[tw`h-8 w-8`]} />
          <div css={[tw`flex flex-col gap-1 text-center`]}>
            <p>{_("Are you sure you want to change Models?")}</p>
            <p css={[tw`text-sm px-4`]}>
              {_("All selected Trims for the current Model will be removed.")}
            </p>
          </div>
          <div css={[tw`flex flex-col gap-3 w-full justify-center`]}>
            <Button
              primary
              onClick={() => {
                setSeriesName(null),
                  setModelName(null),
                  setShowModelVal(false),
                  dispatch(updateModel()),
                  setShowChangeWarning(false)
              }}
            >
              {_("Change Model")}
            </Button>
            <Button secondary onClick={() => setShowChangeWarning(false)}>
              {_("Keep Current Model")}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default SelectVehicleCompareModal
