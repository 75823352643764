import React, { useContext, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Model } from "../global"
import { LanguageContext } from "../contexts/Language"
import { formatModelName, formatSeriesSlug, slugify } from "../helpers"

function reducer(
  state: { [x: string]: any[] },
  model: { series: { name: any } }
) {
  const seriesName = formatSeriesSlug(model.series.name)
  if (!state[seriesName]) {
    state[seriesName] = []
  }
  state[seriesName].push(model)
  return state
}

const useModelCompareData = (
  series: string,
  year: string,
  models: string[],
  data: any
) => {
  const { _, language } = useContext(LanguageContext)
  const [sortedCategories, setSortedCategories] = useState([])
  const [modelsDetails, setModelsDetails] = useState([])
  const [modelIds, setModelIds] = useState([])

  if (series.length === 0) {
    return { sortedCategories, modelsDetails, modelIds, modelsData: {} }
  }

  const modelsData = {
    models: data.models.nodes.reduce(reducer, {}),
  }

  useEffect(() => {
    // Find models that match the series and year
    if (series.length > 0) {
      const seriesModels = modelsData.models[formatSeriesSlug(series)]
      // Find the most recent year of the selected series
      let mostRecentYear = new Date().getFullYear()
      if (Array.isArray(seriesModels) && seriesModels.length > 0) {
        mostRecentYear = Math.max(
          ...seriesModels.map((option: { year: number }) => option?.year)
        )
      }
      const modelsDetails = models
        .map(modelName =>
          seriesModels.find(
            (model: Model) =>
              formatModelName(model.name, series) === modelName &&
              (year?.length > 0
                ? model.year.toString() === year
                : model.year === mostRecentYear)
          )
        )
        .filter(Boolean)
        .map((model: Model) => {
          // create comparisons that are not in the comparison table but are in standard equipment
          const standardEquipmentEn = model.standardEquipment.en
            .flatMap((se: any) => se.items.flatMap((item: any) => item))
            .filter(
              (i: any) =>
                !model?.comparisons?.en?.some(
                  (comparison: any) => comparison.name === i.name
                )
            )
          model?.comparisons?.en?.push(...standardEquipmentEn)

          const standardEquipmentEs = model.standardEquipment.es
            .flatMap((se: any) => se.items.flatMap((item: any) => item))
            .filter(
              (i: any) =>
                !model?.comparisons?.en?.some(
                  (comparison: any) => comparison.name === i.name
                )
            )
          model?.comparisons?.es?.push(...standardEquipmentEs)

          return model
        })

      setModelsDetails(modelsDetails)
      // Combine all the categories and items from all the models
      const combinedCategories = modelsDetails?.flatMap(
        (model: { comparisons: { en: any; es: any } }) => {
          const comp =
            language === "en" ? model?.comparisons?.en : model?.comparisons?.es
          return comp?.map((feature: { name: any; items: any[] }) => {
            return {
              name: feature.name,
              items: feature.items.map(item => item.title),
            }
          })
        }
      )

      // Group the categories and remove duplicates
      const groupedCategories = combinedCategories?.reduce(
        (acc: any, current: any) => {
          const x = acc.find((item: any) => item?.name === current?.name)
          if (!x) {
            return acc.concat([current])
          } else {
            x.items = [...new Set(x.items.concat(current.items))]
            return acc
          }
        },
        []
      )

      const sortedCategories = groupedCategories?.sort((a: any, b: any) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      setSortedCategories(sortedCategories)

      const modelIds =
        models.length > 0 &&
        modelsData.models[formatSeriesSlug(series)]
          .filter((node: { series: any; name: string; year: number }) => {
            return (
              models.includes(
                formatModelName(node?.name, series)?.replace("-", "")
              ) &&
              formatSeriesSlug(node.series.name)?.toLowerCase() ===
                series.toLowerCase() &&
              mostRecentYear === node.year
            )
          })
          .map((node: { code: any }) => {
            return node.code
          })
      setModelIds(modelIds)
    }
  }, [series, year, models])
  return { sortedCategories, modelsDetails, modelIds, modelsData }
}
export default useModelCompareData
