import React, { useRef } from "react"
import tw from "twin.macro"
import ModelCompareCard from "../../../molecules/ModelCompareCard/ModelCompareCard"
import { useContext, useEffect, useState } from "react"
import { ModelComparisonContext } from "../../../../contexts/ModelComparison/context"
import type { ModelComparisonHeroProps } from "./ModelCompareHero.d"
import { Button, ButtonLink } from "../../../atoms/Button"
import { LanguageContext } from "../../../../contexts/Language"
import { css } from "@emotion/react"
import { generateInventoryLink, vehicleCategory } from "../../../../helpers"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import Icon from "../../../atoms/Icon"
import { Link } from "../../../atoms/Link"
import { ContactContext } from "../../../../contexts/Contact"
import {
  openContactDealerModal,
  updateField,
} from "../../../../contexts/Contact/actions"
import LoadingSkeleton from "./LoadingSkeleton"
import PlaceholderModelCompareCard from "../../../molecules/ModelCompareCard/PlaceholderModelCompareCard"
import ModelCompareKey from "../../../molecules/ModelCompareKey/ModelCompareKey"
import useViewportSize from "../../../../hooks/useViewportSize"
import { changeModel } from "../../../../contexts/ModelComparison/actions"

const ModelCompareHero: React.FC<ModelComparisonHeroProps> = ({
  expandAll,
  setExpandAll,
  setInitialLoad,
  modelsDetails,
  modelIds,
}) => {
  const { _, language } = useContext(LanguageContext)
  const [state, dispatch] = useContext(ModelComparisonContext)
  const [_contactState, contactDispatch] = useContext(ContactContext)
  const { trackTealEvent } = useTealiumEvent()

  const { width } = useViewportSize()
  const isDesktop = width > 768

  const trims =
    state.trims.length === 0
      ? ["TBD", "TBD", "TBD"]
      : state.trims.length == 2 && modelsDetails?.length > 0
      ? [...modelsDetails, "TBD"]
      : state.trims.length == 1 && modelsDetails?.length > 0
      ? [...modelsDetails, "TBD", "TBD"]
      : modelsDetails

  const isLoaded = trims?.length > 0

  const stickyRef = useRef(null)

  const [isSticky, setIsSticky] = useState(false)

  const debounce = (func: { (): void; apply?: any }, delay: number) => {
    let debounceTimer: NodeJS.Timeout
    return function () {
      const context = this
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
  }

  useEffect(() => {
    const detectSticky = debounce(() => {
      if (stickyRef?.current?.offsetTop > 118 && state.trims.length > 0) {
        setIsSticky(true)
      } else if (stickyRef?.current?.offsetTop === 118 && window.scrollY == 0) {
        setIsSticky(false)
      }
    }, 300)

    window.addEventListener("scroll", detectSticky)

    return () => {
      window.removeEventListener("scroll", detectSticky)
    }
  }, [])

  const subheadingHoverAnimation = css`
    @media (min-width: 1024px) {
      &:hover > .darkRed {
        ${tw`opacity-100 duration-300 translate-x-11 bottom-0.5`}
      }
      &:hover > .lighterRed {
        ${tw`opacity-100 delay-200 translate-x-8 bottom-0.5`}
      }
      &:hover > .lightestRed {
        ${tw`opacity-100 delay-100 translate-x-5 bottom-0.5`}
      }
    }
  `
  const subheadingFocusAnimation = css`
    @media (min-width: 1024px) {
      &:focus-visible > .darkRed {
        ${tw`opacity-100 duration-300 translate-x-11 bottom-0.5`}
      }
      &:focus-visible > .lighterRed {
        ${tw`opacity-100 delay-200 translate-x-8 bottom-0.5`}
      }
      &:focus-visible > .lightestRed {
        ${tw`opacity-100 delay-100 translate-x-5 bottom-0.5`}
      }
    }
  `
  return (
    <>
      <div
        ref={stickyRef}
        css={[
          tw`w-full bg-white md:(sticky top-16) min-h-[10rem] md:(min-h-[16rem]) xl:(min-h-[14rem])`,
          _contactState.isContactDealerModalOpen ? tw`z-[600]` : tw`z-[996]`,
          `transition: all 0.3s ease-in-out;`,
        ]}
      >
        <div css={[tw`w-full bg-white max-w-desktop mx-auto relative xl:()`]}>
          <div
            css={[
              tw`grid grid-cols-1 auto-rows-auto gap-x-5 p-5  md:(grid-cols-4) xl:(grid-cols-4 px-20 pt-10) `,
            ]}
          >
            <div
              css={[
                tw`col-span-1 md:col-span-4 xl:col-span-1 flex flex-col `,
                isSticky ? tw`gap-0` : tw`gap-4 xl:gap-12`,
              ]}
            >
              <div css={[tw`flex flex-col gap-2`]}>
                <h1
                  css={[
                    tw`tracking-widest font-light leading-[3.25rem] mt-5 xl:mt-0`,
                    isSticky ? tw`text-2xl` : tw`text-4xl`,
                  ]}
                >
                  {state.model
                    ? _("Compare") + " " + state.model + " " + _("Trims")
                    : _("Compare Toyota Trims")}
                </h1>
                {state?.model && (
                  <button
                    css={[
                      tw`relative text-sm font-semibold whitespace-nowrap flex justify-start w-min ml-1`,
                      tw`focus-visible:outline-gray`,
                      tw`xl:text-base`,
                      subheadingHoverAnimation,
                      subheadingFocusAnimation,
                    ]}
                    onClick={() => {
                      dispatch(changeModel(state.model))
                    }}
                  >
                    {_("Change Model")}
                    <Icon.Chevron
                      direction="right"
                      color="red-200"
                      className="lightestRed"
                      css={[
                        tw`absolute bottom-0.5 right-2 h-3 translate-x-5 -translate-y-0.5 opacity-0`,
                      ]}
                    />
                    <Icon.Chevron
                      direction="right"
                      color="red-300"
                      className="lighterRed"
                      css={[
                        tw`absolute bottom-0.5 right-2 h-3 translate-x-5 -translate-y-0.5 opacity-0`,
                      ]}
                    />
                    <Icon.Chevron
                      direction="right"
                      color="red-400"
                      className="darkRed"
                      css={[
                        tw`absolute bottom-0.5 right-2 h-3 translate-x-5 -translate-y-0.5`,
                      ]}
                    />
                  </button>
                )}
              </div>

              <div>
                {state.trims.length > 0 ? (
                  <div
                    css={[
                      tw`flex flex-col md:flex-row xl:flex-col gap-3 items-center`,
                      `transition: all 0.3s ease-in-out;`,
                      isSticky ? tw`opacity-0 h-0` : tw`opacity-100 h-[9rem]`,
                    ]}
                  >
                    <ButtonLink
                      secondary
                      to={generateInventoryLink({
                        series: state?.model?.toLowerCase(),
                        modelNumber: modelIds.toString().toLowerCase(),
                      })}
                      onClick={() => {
                        trackTealEvent({
                          tealium_event: "view_inventory_click",
                          vehicle_model_truncated: state.model,
                          coupon_module_text: `View ${state.model} Inventory`,
                          vehicle_year: state.year,
                          vehicle_model: state.model,
                          vehicle_segment_vehicle_page: vehicleCategory(
                            state.model.toLowerCase()
                          ),
                          link_href: generateInventoryLink({
                            series: state?.model?.toLowerCase(),
                          }),
                        })
                      }}
                      analytics-id={`inventory:hero:`}
                      css={[
                        tw`w-full md:w-min whitespace-nowrap xl:w-full`,
                        state.model.length > 15 && tw`xl:text-xs`,
                      ]}
                    >
                      {language === "en" ? (
                        <>
                          {_("View")}
                          {` ${state.model}`}
                          <span> {_("Inventory")}</span>
                        </>
                      ) : (
                        <>
                          <div>
                            {_("View Inventory")} <span> {state.model}</span>
                          </div>
                        </>
                      )}
                    </ButtonLink>
                    <Button
                      primary
                      title={_("Contact Dealer")}
                      css={[
                        tw`w-full md:w-min whitespace-nowrap text-xs xl:(min-w-[15rem] w-full text-sm)`,
                      ]}
                      onClick={() => {
                        contactDispatch(
                          updateField(
                            "comments",
                            _("I'm interested in the") +
                              " " +
                              state?.model +
                              " " +
                              state?.trims
                          )
                        )
                        contactDispatch(
                          openContactDealerModal(
                            null,
                            {
                              series: state?.model,
                              trim: null,
                            },
                            "Model & Trim Availability",
                            "ContactDealer"
                          )
                        )
                      }}
                    >
                      {_("Contact Dealer")}
                    </Button>
                  </div>
                ) : (
                  <div css={[tw`text-xl pr-3`]}>
                    {_(
                      "Select a model and add it to the comparison tool to discover the difference between trims and to find your perfect match"
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* Desktop Cards */}
            {isLoaded && isDesktop
              ? trims?.map((trim: any, index: number) => {
                  return trim === "TBD" ? (
                    <PlaceholderModelCompareCard
                      isSticky={isSticky}
                      index={0}
                    />
                  ) : (
                    <div
                      key={index}
                      css={[tw`col-span-1`, index == 0 && tw`md:col-start-2 `]}
                    >
                      <ModelCompareCard
                        index={index}
                        model={trim}
                        isSticky={isSticky}
                      />
                    </div>
                  )
                })
              : isDesktop && <LoadingSkeleton />}
          </div>
        </div>
        {state.trims.length > 0 && (
          <div css={[tw`flex flex-row max-w-desktop mx-auto`]}>
            <div css={[tw`hidden md:(inline-block text-toyotaRed )`]}>
              <Link
                onClick={() => {
                  setExpandAll(!expandAll), setInitialLoad(false)
                }}
                css={[tw`flex gap-3 cursor-pointer pl-4`]}
              >
                <Icon.Chevron
                  direction="down"
                  color="red-400"
                  css={[
                    tw`w-2 transition-transform duration-500`,
                    expandAll && tw`transform -rotate-180`,
                  ]}
                />
                {expandAll ? _("Collapse All") : _("Expand All")}
              </Link>
            </div>
            <div
              css={[
                tw`hidden mx-auto p-4 md:(flex flex-grow mx-0) justify-center gap-x-4 text-sm text-gray-600`,
              ]}
            >
              <ModelCompareKey />
            </div>
          </div>
        )}
      </div>
      {/* Mobile Cards and key*/}
      <div css={[tw`flex overflow-y-auto p-5 md:(hidden)`]}>
        {isLoaded ? (
          trims?.map((trim: any, index: number) => {
            return trim === "TBD" ? (
              <PlaceholderModelCompareCard isSticky={isSticky} index={0} />
            ) : (
              <div
                key={index}
                css={[tw`col-span-1`, index == 0 && tw`md:col-start-2 `]}
              >
                <ModelCompareCard
                  index={index}
                  model={trim}
                  isSticky={isSticky}
                />
              </div>
            )
          })
        ) : (
          <LoadingSkeleton />
        )}
      </div>
      {state.trims.length > 0 && (
        <div
          css={[
            tw`sticky top-16 bg-white py-4 flex mx-auto z-[996] justify-center gap-x-4 text-sm text-gray-600 md:(hidden)`,
          ]}
        >
          <ModelCompareKey />
        </div>
      )}
    </>
  )
}

export default ModelCompareHero
