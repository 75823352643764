import tw from "twin.macro"
import { ModelCompareCardProps } from "./ModelCompareCard.d"
import { Image } from "../../atoms/Image"
import bgMysticBlur from "../../../images/bg_mystic.png"
import { ContactContext } from "../../../contexts/Contact"
import { useContext } from "react"
import { LanguageContext } from "../../../contexts/Language"
import { ModelComparisonContext } from "../../../contexts/ModelComparison/context"
import {
  changeTrim,
  removeTrim,
} from "../../../contexts/ModelComparison/actions"
import { formatModelName } from "../../../helpers"
import Icon from "../../atoms/Icon"

const ModelCompareCard = ({
  model,
  index,
  isSticky,
}: ModelCompareCardProps) => {
  const { _ } = useContext(LanguageContext)
  const [_contactState, contactDispatch] = useContext(ContactContext)
  const [state, dispatch] = useContext(ModelComparisonContext)
  const modelName = formatModelName(model?.name, state.model)
  return (
    <div
      key={model?._id}
      css={[
        tw`w-full h-full px-2 lg:px-8 rounded-lg min-w-[16rem] md:min-w-0`,
        index % 2 !== 0 && tw`bg-gray-50`,
        isSticky ? tw`py-0` : tw`py-4`,
      ]}
    >
      <div
        css={[
          tw`flex justify-end`,
          `transition: all 0.3s ease-in-out;`,
          isSticky ? tw`opacity-0 h-0` : tw`opacity-100 h-[1rem]`,
        ]}
      >
        <button onClick={() => dispatch(removeTrim(modelName))}>
          <Icon.X color="black" css={[tw`w-4`]} />
        </button>
      </div>
      <div
        css={[
          tw`flex flex-col h-full relative `,
          `transition: all 0.3s ease-in-out;`,
          isSticky ? tw`justify-center py-0` : tw`justify-between py-5`,
        ]}
      >
        <div
          css={[
            `transition: all 0.3s ease-in-out;`,
            isSticky ? tw`opacity-0 h-0` : tw`opacity-100 h-[9rem]`,
          ]}
        >
          <div
            css={[
              tw`absolute top-[-4.25rem] left-0 right-0 w-auto lg:w-[10rem] xl:w-[15rem] h-[18rem] flex items-center justify-center pointer-events-none`,
              `background-image: url(${bgMysticBlur})`,
              `background-repeat: no-repeat;`,
              `background-position: center;`,
              `background-size: 10rem 10rem;`,
            ]}
          >
            <Image
              css={[tw`max-w-[9rem] lg:max-w-[12rem] xl:max-w-[15rem]`]}
              imageData={model?.colors[0]?.exteriorImages[1]}
            />
          </div>
        </div>
        <div>
          <p css={[tw`font-semibold`]}>{model?.year}</p>
          <p css={[tw`font-semibold uppercase text-xl`]}>
            {model?.series?.name} {model?.grade}
          </p>
          <p>{modelName}</p>
          <div
            css={[
              tw`flex gap-3 py-4`,
              `transition: all 0.3s ease-in-out;`,
              isSticky ? tw`opacity-0 h-0` : tw`opacity-100 h-[2rem]`,
            ]}
          >
            <button
              css={[tw`text-toyotaRed text-sm`]}
              onClick={() => {
                dispatch(changeTrim(modelName))
              }}
            >
              {_("Change")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelCompareCard
