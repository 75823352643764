import tw from "twin.macro"

const LoadingSkeleton = () => {
  return (
    <div
      css={[
        tw`grid grid-cols-2 col-span-2 gap-x-5 gap-y-5 p-5 md:(col-span-3 grid-cols-3 col-start-2) xl:(col-span-3 grid-cols-3) animate-pulse`,
      ]}
    >
      <div
        css={[
          tw`col-span-1 w-full h-full py-4 px-2 lg:px-8 rounded-lg bg-gray-400 min-h-[300px]`,
        ]}
      ></div>
      <div
        css={[
          tw`col-span-1 w-full h-full py-4 px-2 lg:px-8 rounded-lg bg-gray-400 min-h-[300px]`,
        ]}
      ></div>
      <div
        css={[
          tw`col-span-1 w-full h-full py-4 px-2 lg:px-8 rounded-lg bg-gray-400 min-h-[300px]`,
        ]}
      ></div>
    </div>
  )
}

export default LoadingSkeleton
